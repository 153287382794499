import { CLOSE_MODAL, OPEN_MODAL } from "./modalActions";


export const modalReducer = (state = false, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return state = true
            break;
        case CLOSE_MODAL:
            return state = false
            break;
        case 'MESSAGE_POST':
            return state = false

        default:
            return state
            break;
    }
}