// room1
import plan1 from '../assets/images/block-1/rooms-1/1.png'
import plan3 from '../assets/images/block-1/rooms-1/3.png'
import plan4 from '../assets/images/block-1/rooms-1/4.png'
import plan5 from '../assets/images/block-1/rooms-1/5.png'
import plan6 from '../assets/images/block-1/rooms-1/6.png'
import plan8 from '../assets/images/block-1/rooms-1/8.png'
import plan9 from '../assets/images/block-1/rooms-1/9.png'
import plan10 from '../assets/images/block-1/rooms-1/10.png'
import plan11 from '../assets/images/block-1/rooms-1/11.png'
import plan12 from '../assets/images/block-1/rooms-1/12.png'
import plan13 from '../assets/images/block-1/rooms-1/13.png'
import plan14 from '../assets/images/block-1/rooms-1/14.png'
import plan15 from '../assets/images/block-1/rooms-1/15.png'
import plan16 from '../assets/images/block-1/rooms-1/16.png'
import plan17 from '../assets/images/block-1/rooms-1/17.png'
import plan18 from '../assets/images/block-1/rooms-1/18.png'
import plan19 from '../assets/images/block-1/rooms-1/19.png'
import plan20 from '../assets/images/block-1/rooms-1/20.png'
import plan21 from '../assets/images/block-1/rooms-1/21.png'
import plan22 from '../assets/images/block-1/rooms-1/22.png'
import plan23 from '../assets/images/block-1/rooms-1/23.png'
import plan24 from '../assets/images/block-1/rooms-1/24.png'

import room2Plan1 from '../assets/images/block-1/rooms-2/1.png'
import room2Plan2 from '../assets/images/block-1/rooms-2/2.png'
import room2Plan3 from '../assets/images/block-1/rooms-2/3.png'
import room2Plan4 from '../assets/images/block-1/rooms-2/4.png'
import room2Plan5 from '../assets/images/block-1/rooms-2/5.png'
import room2Plan6 from '../assets/images/block-1/rooms-2/6.png'
import room2Plan7 from '../assets/images/block-1/rooms-2/7.png'
import room2Plan8 from '../assets/images/block-1/rooms-2/8.png'
import room2Plan9 from '../assets/images/block-1/rooms-1/2.png'
import room2Plan10 from '../assets/images/block-1/rooms-1/7.png'



// block2
// room1
import room1Block2Img1 from '../assets/images/block-2/room-1/1.png'
import room1Block2Img2 from '../assets/images/block-2/room-1/2.png'
import room1Block2Img3 from '../assets/images/block-2/room-1/3.png'
import room1Block2Img4 from '../assets/images/block-2/room-1/4.png'
import room1Block2Img5 from '../assets/images/block-2/room-1/5.png'
import room1Block2Img6 from '../assets/images/block-2/room-1/6.png'
import room1Block2Img7 from '../assets/images/block-2/room-1/7.png'
import room1Block2Img8 from '../assets/images/block-2/room-1/8.png'
import room1Block2Img9 from '../assets/images/block-2/room-1/9.png'
import room1Block2Img10 from '../assets/images/block-2/room-1/10.png'
import room1Block2Img11 from '../assets/images/block-2/room-1/11.png'
import room1Block2Img12 from '../assets/images/block-2/room-1/12.png'
import room1Block2Img13 from '../assets/images/block-2/room-1/13.png'
import room1Block2Img14 from '../assets/images/block-2/room-1/14.png'


// block2
// room2
import room2Block2Img1 from '../assets/images/block-2/room-2/1.png'
import room2Block2Img2 from '../assets/images/block-2/room-2/2.png'
import room2Block2Img3 from '../assets/images/block-2/room-2/3.png'
import room2Block2Img4 from '../assets/images/block-2/room-2/4.png'
import room2Block2Img5 from '../assets/images/block-2/room-2/5.png'
import room2Block2Img6 from '../assets/images/block-2/room-2/6.png'


export const block1Room1 = [
    {
      img: plan1,
      rooms: '1',
      square: '55,70м2'
    },
    {
      img: plan3,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan4,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan5,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan6,
      rooms: '1',
      square: '58,80м2'
    },

    {
      img: plan8,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan9,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan10,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan11,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan12,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan13,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan14,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan15,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan16,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan17,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan18,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan19,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan20,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan21,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan22,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan23,
      rooms: '1',
      square: '58,80м2'
    },
    {
      img: plan24,
      rooms: '1',
      square: '58,80м2'
    },
    
  ]
  

export const block1Room2 = [
  {
    img: room2Plan1,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan2,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan3,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan4,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan5,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan6,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan7,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan8,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan9,
    rooms: '1',
    square: '58,80м2'
  },
  {
    img: room2Plan10,
    rooms: '1',
    square: '58,80м2'
  },
]


export const block2Room1 = [
  {
    img: room1Block2Img1
  },
  {
    img: room1Block2Img2
  },
  {
    img: room1Block2Img3
  },
  {
    img: room1Block2Img4
  },
  {
    img: room1Block2Img5
  },
  {
    img: room1Block2Img6
  },
  {
    img: room1Block2Img7
  },
  {
    img: room1Block2Img8
  },
  {
    img: room1Block2Img9
  },
  {
    img: room1Block2Img10
  },
  {
    img: room1Block2Img11
  },
  {
    img: room1Block2Img12
  },
  {
    img: room1Block2Img13
  },
  {
    img: room1Block2Img14
  },
]

export const block2Room2 = [
  {
    img: room2Block2Img1
  },
  {
    img: room2Block2Img2
  },
  {
    img: room2Block2Img3
  },
  {
    img: room2Block2Img4
  },
  {
    img: room2Block2Img5
  },
  {
    img: room2Block2Img6
  },

]