export const OPEN_MODAL = 'OPEN_MODAL'

export const CLOSE_MODAL = 'CLOSE_MODAL'

export const MESSAGE_POST = 'MESSAGE_POST'


export const openModal = () => ({
    type: OPEN_MODAL
})


export const closeModal = () => ({
    type: CLOSE_MODAL
})

