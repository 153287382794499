import React from 'react'
import Container from '../Container'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import cls from './Contact.module.scss'
import location from '../../assets/icons/location.svg'
import callIcon from '../../assets/icons/call.svg'
import btnImg from '../../assets/images/yandexMap.png'
import logo from '../../assets/images/logo.png'
import telegram from '../../assets/icons/telegram.svg'
import instagram from '../../assets/icons/instagram.svg'
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className={cls['contact']}>

      {/* <h2 className={cls['contact__title']}>Контакты</h2> */}
      <div className={cls['contact__wrapper']}>
        <YMaps>
          <div>
            <Map
              defaultState={{ center: [41.322075, 69.322451], zoom: 9 }}
              width='100%'
              height='750px'
            >
              <Placemark height={'100%'} geometry={[41.322075, 69.322451]} />
            </Map>
            <div className={cls['contact__info']}>
              <h3 className={cls["contact__info-title"]}>
                Как добраться
              </h3>
              <div className={cls['contact__info-location']}>
                <img src={location} alt="location" />
                <p>Мирзо Улугбекский район, улица Мирзо Улугбек, 42</p>
              </div>
              <div className={cls['contact__info-number']}>
                <img src={callIcon} alt="call" />
                <a href="tel:+998908828888 ">+998 90-882-88-88 </a>
              </div>
              <a href="https://yandex.uz/maps/10335/tashkent/house/YkAYdQViTUAGQFprfX9zc3xkYQ==/?ll=69.322658%2C41.322027&z=18.58" className={cls['contact__info-link']}>
                <img src={btnImg} alt="yandexMap" />
              </a>
            </div>
          </div>
        </YMaps>
      </div>


      <footer className={cls['footer']}>
        <Container>
          <div className={cls['footer__wrapper']}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <div className={cls['footer__socials']}>
              <p>Мы социальных сетях</p>
              <a href="">
                <img src={telegram} alt="telegram" />
              </a>
              <a target="_blank" href="https://instagram.com/ipakyoli.uz?igshid=Zjc2ZTc4Nzk=">
                <img src={instagram} alt="instagram" />
              </a>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  )
}

export default Contact