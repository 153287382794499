import React from 'react'
import Container from '../Container'


import cls from './AboutCompany.module.scss'
import aboutImg from '../../assets/images/aboutPageimg.png'
import aboutImgsecond from '../../assets/images/aboutPageimgsecond.png'

const AboutCompany = () => {
  return (
    <div className={cls['about']}>

      <div className={cls['about__wrapper']}>
        <div className={cls['about__content']}>
          <h2>О компании</h2>
          <p><span>IPAK YO`LI</span> — это уникальный жилой комплекс вашей мечты. Наша основная задача строить ваше надежное и комфортное будущее! Простор и комфорт — все что нужно для идеальной квартиры! ЖК «IPAK YO’LI» предоставляет апартаменты именно для Вас! </p>
          <p>Жилой комплекс IPAK YO’LI - это лидер по мнению экспертов по строительству домов который присутствует на рынке уже 5 лет. За все время нами было построено более 50 новостроек, являющимися символом и гарантии и  качества среди наших клиентов!</p>
          <p>В отличие от своих аналогов в мире, символ желаемого комфорта IPAK YO’LI не просто предлагает жилое пространство, но и предлагает своим жителям философию жизни, о которой они мечтали и которую откладывали на долгое время.</p>
        </div>
        <img src={aboutImg} alt="home" />
      </div>
      <div className={cls['about__wrapper']}>
        <img src={aboutImgsecond} alt="home" />
        <div className={cls['about__content']}>
          <h2>Жилой комплекс</h2>
          <p>Жилой комплекс IPAK YO’LI - это лидер по мнению экспертов по строительству домов который присутствует на рынке уже ? лет (года). За все время нами было построено более ? новостроек, являющимися символом и гарантии и  качества среди наших клиентов!</p>
          <p>Ваш дом, строится из самых прочных материалов! Вовремя выбора жилья для себя и своей семьи, важно учитывать не только расположение и стоимость, но и конечно же то, из чего будет строится ваш дом! Жилой Комплекс IPAK YO’LI состоит из прочного материала, а также из самых качественных строительных компонентов от именных брендов, зарекомендовавших себя высоким стандартом качества! IPAK YO’LI — качество, проявляющееся во всем.</p>
          <p>Фундамент здания рассчитан на 8 бальное землетрясение и строится по уникальным ? технологиям! Тип дома состоит из высококачественного монолита и газ блока, что даст вам уверенность в надежности конструкции.</p>
        </div>

      </div>
    </div>
  )
}

export default AboutCompany