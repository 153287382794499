import React from 'react'
import AboutCompany from '../../components/AboutCompany'
import Contact from '../../components/Contact'
import Navbar from '../../components/Navbar'

import cls from './AboutPage.module.scss'

const About = () => {
    return (
        <div className={cls['about']}>
            <Navbar />
            <AboutCompany />
            <Contact />
        </div>
    )
}

export default About