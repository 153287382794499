import React from 'react'
import Contact from '../../components/Contact'
import Navbar from '../../components/Navbar'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import cls from './SecurityPage.module.scss'
import aboutImg from '../../assets/images/aboutPageimg.png'
import street1 from '../../assets/images/street1.jpg'
import street2 from '../../assets/images/street2.jpg'
import street3 from '../../assets/images/street3.jpg'
import street4 from '../../assets/images/street4.jpg'
import street5 from '../../assets/images/street5.jpg'
import street6 from '../../assets/images/street6.jpg'
import street7 from '../../assets/images/street7.jpg'
import street8 from '../../assets/images/street8.jpg'



const slide_img = [
    street1,
    street2,
    street3,
    street4,
    street5,
    street6,
    street7,
    street8,
];
const Security = () => {
    SwiperCore.use([Autoplay]);
    return (
        <div className={cls['security']}>
            <Navbar />
            <div className={cls['about']}>

                <div className={cls['about__wrapper']}>
                    <div className={cls['about__content']}>
                        <h2>Безопасность двора</h2>
                        <p>Ваш дом, строится из самых прочных материалов. Вовремя выбора жилья для себя и своей семьи, важно учитывать не только расположение и стоимость, но и конечно же то, из чего будет строится ваш дом! Жилой Комплекс IPAK YO’LI состоит из прочного материала, а также из самых качественных строительных компонентов от именных брендов, зарекомендовавших себя высоким стандартом качества! IPAK YO’LI — качество, проявляющееся во всем.

                            Фундамент здания рассчитан на 8 бальное землетрясение и строится по уникальным технологиям! Тип дома состоит из высококачественного монолита и газоблока, что даст вам уверенность в надежности конструкции.
                            Внутренняя инфраструктуру комплекса имеет обширные удобства и все это в доступном шагу: Магазин, клиника, зона озеленения, детская площадка, барбекю зона! Так же имеются максимально ближайшие, внешние инфраструктуры такие, как Паркентский базар, Парка Ашхабад, Максима Горький!

                            ОХРАНЯЕМАЯ ТЕРРИТОРИЯ 24/7! Одной из наших главных задач является спокойное и надёжное проживание каждого из будущих жителей ЖК “IPAK YO’LI. Опираясь на это, мы заранее предусмотрели все необходимое по части безопасности нашего комплекса. Поэтому территория ЖК IPAK YO’LI будет закрыта для посторонних людей, а также здесь будет вестись круглосуточное видеонаблюдение.</p>
                    </div>
                    <img src={aboutImg} alt="home" />
                </div>
            </div>
            <>
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    modules={[Pagination, Autoplay]}

                    className="mySwiper"
                >

                    {slide_img.map((img, i) => (
                        <SwiperSlide key={i}>
                            <img src={img} alt="" />
                        </SwiperSlide>
                    ))}

                </Swiper>
            </>
            <Contact />
        </div>
    )
}

export default Security