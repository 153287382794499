import { Routes, Route } from 'react-router-dom'
import About from './pages/AboutPage';


import Home from "./pages/MainPage";
import SalePage from './pages/SalePage';
import Security from './pages/SecurityPage';
import call from './assets/icons/call.svg'


function App() {
  return (
    <div className="App">

      <Routes>
        <Route>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/security' element={<Security />} />
          <Route path='/actions' element={<SalePage />} />
        </Route>
      </Routes>
      <a href='tel:+998908828888' className="callback-bt">
        <div className="text-call">
          <span>Заказать звонок</span>
        </div>
      </a>
    </div>
  );
}

export default App;
