import classNames from 'classnames';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import cls from './Modal.module.scss';
import modalIcon from '../../assets/icons/modalIcon.svg';
import { postMsg } from '../../store/modalPost/modalPostActions';






const Modal = () => {
    const [info, setInfo] = React.useState({ name: '', phone: '' })

    const [isDirty, setIsDirty] = React.useState(false)
    const [nameError, setNameError] = React.useState('Имя не может быть пустым')
    const [phoneError, setPhoneError] = React.useState('Номер телефона не может быть пустым')



    const dispatch = useDispatch()
    const { modal } = useSelector(state => state)


    const handleCloseModal = () => {
        dispatch({ type: 'CLOSE_MODAL' })
        setIsDirty(false)
    }

    const handleInput = (e) => {
        setInfo({ ...info, [e.target.name]: e.target.value })
    }

    const handlePostMessage = (e) => {
        e.preventDefault()
        if (info.name && info.phone) {
            postMsg(info.name, info.phone)
            setInfo({ name: '', phone: '' })
            handleCloseModal()
        } else {
            setIsDirty(true)
            setNameError('Имя не может быть пустым')
            setPhoneError('Номер телефона не может быть пустым')
        }
    }

    const onBlur = (e) => {
        switch (e.target.name) {
            case 'name':
                if (e.target.value.length < 3) {
                    setNameError('Имя не может быть меньше 3 символов')
                }
                break;
            default:
                break;
        }
        setIsDirty(true)

    }

  

    return (
        <div onClick={handleCloseModal} className={classNames(cls['modal'], {
            [cls['active']]: modal
        })}>
            <div onClick={(e) => e.stopPropagation()} className={cls['modal__wrapper']}>
                <div className={cls['modal__content']}>
                    <h2 className={cls["modal__title"]}>ПОЛУЧИТЬ
                        БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ</h2>
                    <p className={cls["modal__subtitle"]}>ЗАПОЛНИТЕ ФОРМУ И ПОЛУЧИТЕ КОНСУЛЬТАЦИЮ</p>

                    <form className={cls['modal__form']}>

                        {isDirty && nameError && <div className={cls['error']} >{nameError}</div>}
                        <input onBlur={onBlur} required value={info.name} onChange={handleInput} name='name' type="text" placeholder='Ваше имя' />


                        {isDirty && phoneError && <div className={cls['error']}>{phoneError}</div>}
                        <input onBlur={onBlur} required value={info.phone} onChange={handleInput} name='phone' type="number" placeholder='+998 94 444 44 44' />
                        <button onClick={handlePostMessage}>ПРОКОНСУЛЬТИРОВАТЬСЯ</button>
                    </form>
                </div>
                <img src={modalIcon} alt="ipay-yoli-logo" />
            </div>
        </div>
    )
}

export default Modal