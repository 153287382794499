import React from 'react'
import Container from '../Container'
import Navbar from '../Navbar'
import { useDispatch } from 'react-redux'
import bigCloud from '../../assets/images/cloud1.png'

import cls from './Header.module.scss'
import { openModal } from '../../store/modal/modalActions'
import Modal from '../Modal'


const Header = () => {
  const dispatch = useDispatch()



  const handleOpenModal = (e) => {
    e.preventDefault()
    dispatch(openModal())
  }


  return (
    <header className={cls['header']}>
      <Navbar />
      <div className={cls['header__wrapper']}>
        <Container>
          <div className={cls['header__content']}>
            <h1 className={cls['header__title']}>Единый центр недвижимости в <span>Ташкенте</span></h1>
            <p className={cls['header__subtitle']}>Современный способ купить квартиру</p>
            <div className={cls['header__buttons']}>
              <button onClick={handleOpenModal} className={cls['header__button']}>Подобрать квартиру</button>
            </div>
          </div>
        </Container>
        <img src={bigCloud} alt="cloud" className={cls['header__cloud']} />
      </div>
      <Modal />
    </header>
  )
}

export default Header