import classNames from 'classnames'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { block1Room1, block1Room2, block2Room1, block2Room2 } from '../../data'
import { openModal } from '../../store/modal/modalActions'
import Modal from '../Modal'

import cls from './PlanModal.module.scss'




const PlanModal = ({ close, blockFirst, blockSecond, blockThird }) => {
  const [filter, setFilter] = useState(0)
  const dispatch = useDispatch()

  const handleOpenModal = (e) => {
    e.preventDefault()
    dispatch(openModal())
  }


  const onChangeFirstFilter = (e) => {
    if (e.target.checked) {
      setFilter(1)
    }

  }
  const onChangeSecondFilter = (e) => {
    if (e.target.checked) {
      setFilter(2)
    }

  }



  return (
    <>
      <div className={classNames(cls['planModal'], {
        [cls['active']]: blockFirst || blockSecond || blockThird
      })}>
        <div className={cls['planModal__wrapper']}>
          <h2>Доступные планировки в здании </h2>
          <span onClick={close}>X</span>
        </div>
        <div className={cls['planModal__formBlock']}>
          <h4>Выберите количество желаемых комнат: </h4>
          <div className={cls['planModal__radio']}>
            <input type="radio" name='filter' id="1" onChange={onChangeFirstFilter} />
            <label htmlFor="1" className={cls['planModal__label']}>
              <span> 1-комнатные</span>
            </label>
          </div>
          <div className={cls['planModal__radio']}>
            <input type="radio" name='filter' id="2" onChange={onChangeSecondFilter} />
            <label htmlFor="2" className={cls['planModal__label']}>
              <span> 2-комнатные</span>
            </label>
          </div>

        </div>
        <div className={cls['planModal__block']}>

          {blockFirst && filter === 1 && block1Room1.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}
          {blockFirst && filter === 2 && block1Room2.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}
          {blockThird && filter === 1 && block1Room1.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}
          {blockThird && filter === 2 && block1Room2.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}
          {blockSecond && filter === 1 && block2Room1.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}
          {blockSecond && filter === 2 && block2Room2.map((item, i) => (
            <div className={cls['planModal__block-item']}>
              <img src={item.img} alt="" />
              <button onClick={handleOpenModal}>Подробнее</button>
            </div>
          ))}

        </div>
      </div>
      <Modal />
    </>
  )
}

export default PlanModal