import React from 'react'
import { Link, useResolvedPath, useMatch } from 'react-router-dom'
import classNames from 'classnames'
import Container from '../Container'

import cls from './Navbar.module.scss'
import headerLogo from '../../assets/images/logo.png'
import switcherIcon from '../../assets/icons/language.svg'



const Navbar = () => {
    const [openMenu, setOpenMenu] = React.useState(false)


    const handleOpenMenu = (e) => {
        e.preventDefault()
        setOpenMenu(!openMenu)
    }


    return (
        <nav className={cls['nav']}>
            <Container>
                <div className={cls['nav__wrapper']}>
                    <div className={cls['nav__logo']}>
                        <Link to='/'>
                            <img src={headerLogo} alt="logo" />
                        </Link>
                    </div>
                    <ul className={classNames(cls['nav__list'], {
                        [cls['active']]: openMenu
                    })}>
                        <CustomLink to='/'>Главная</CustomLink>
                        <CustomLink to='/about'>О нас</CustomLink>
                        <CustomLink to='/security'>Безопасность</CustomLink>
                        <CustomLink to='/actions'>Акции</CustomLink>
                        {/* <CustomLink to='/securityYard'>Безопасность двора</CustomLink> */}
                    </ul>
                    <div className={cls['nav__contact']}>
                        <a className={cls['nav__call']} href="tel:+998908828888">+998 90-882-88-88 <span>готовы к вашему звонку</span></a>
                        {/* <a href="" className={cls['nav__switcher']}>
                            <img src={switcherIcon} alt="switcher" />
                            RU
                        </a> */}
                    </div>
                    <button onClick={handleOpenMenu} className={classNames(cls['nav__btn'], {
                        [cls['active']]: openMenu
                    })}>
                        <span className={cls["nav__btn-burger"]}></span>
                    </button>
                </div>
            </Container>
        </nav>
    )
}


function CustomLink({ to, children }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <li >
            <Link className={classNames(cls['nav__list-link'], (
                isActive && cls['active']
            ))} to={to} >
                {children}
            </Link>
        </li>
    )
}

export default Navbar