import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom'
import './styles/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
      <HashRouter >
        <App />
      </HashRouter>
    </Provider>

);


