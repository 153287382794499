import React from 'react'
import Container from '../Container'


import cls from './ProjectVideo.module.scss'
import video from '../../assets/videos/videoBlock.MP4'

const ProjectVideo = () => {
    return (
        <div className={cls['videoBlock']}>
            <Container>
                <h2 className={cls['videoBlock__title']}>Видео о проекте</h2>
                <video controls src={video} className={cls['videoBlock__video']}></video>
            </Container>
        </div>
    )
}

export default ProjectVideo