import React from 'react'
import Accordion from '../../components/Accordion'
import ChooseLayout from '../../components/ChooseLayout'
import Complex from '../../components/Complex'
import Header from '../../components/Header'
import ProjectVideo from '../../components/ProjectVideo'

import cls from '../../components/Accordion/Accordion.module.scss'
import Container from '../../components/Container'
import Contact from '../../components/Contact'

const accordionData = [
    {
        title: 'Сдается ли квартира с ремонтом?',
        content: 'Нет! Квартиры у нас идут без ремонта'
    },
    {
        title: 'Где ваш торговый офис?',
        content: 'Торговый офис находится по адресу: Мирзо Улугбекский район, улица Мирзо Улугбек, 42'
    },
]


const Home = () => {
    return (
        <div>
            <Header />
            <Complex />
            {/* <ChooseLayout /> */}
            <ProjectVideo />
            <div className={cls['accordion']}>
                <Container>
                    <h2 className={cls['accordion__title']}>Часто задаваемые вопросы</h2>
                    {accordionData.map((item, index) => (
                        <Accordion key={index} title={item.title} content={item.content} />
                    ))}
                </Container>
            </div>
            <Contact />
        </div>
    )
}

export default Home