import classNames from 'classnames'
import React, { useState } from 'react'


import cls from './Accordion.module.scss'
import closeIcon from '../../assets/icons/accordionIcon-close.svg'
import openIcon from '../../assets/icons/accordionIcon-open.svg'




const Accordion = ({ title, content }) => {
    const [active, setActive] = useState(false)


    const handleActive = () => {
        setActive(!active)
    }

    return (
                <div onClick={handleActive} className={cls['accordion__items']}>

                    <div className={cls['accordion__item']}>
                        <button  className={cls['accordion__btn']}>{title}
                            <img src={active ? closeIcon : openIcon} alt="" />
                        </button>
                        <div className={classNames(cls['accordion__content'], {
                            [cls['active']]: active
                        })}>
                            <p>{content}</p>
                        </div>
                    </div>
                </div>
    )
}

export default Accordion