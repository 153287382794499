import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/modal/modalActions'
import Modal from '../Modal'

import blocksImg from '../../assets/images/complex.png'

import cls from './Complex.module.scss'
import PlanModal from '../PlanModal'





const Complex = () => {
    const [blockFirst, setBlockFirst] = useState(false)
    const [blockSecond, setBlockSecond] = useState(false)
    const [blockThird, setBlockThird] = useState(false)

    const dispatch = useDispatch()

    const handleOpenFirst = () => {
        setBlockFirst(true)
    }
    const handleOpenSecond = () => {
        setBlockSecond(true)
    }
    const handleOpenThird = () => {
        setBlockThird(true)
    }

    const handleClose = () => {
        setBlockFirst(false)
        setBlockSecond(false)
        setBlockThird(false)
    }




    const handleOpenModal = (e) => {
        e.preventDefault()
        dispatch(openModal())
    }


    return (
        <div className={cls['complex']}>
            <h1 className={cls['complex__title']}>УНИКАЛЬНОСТЬ КОМПЛЕКСА</h1>
            <div className={cls['complex__content']}>
                <img className={cls['complex__content-img']} src={blocksImg} alt="blocks" />
                <button onClick={handleOpenFirst} className={cls['complex__btn']}>Блок 1</button>
                <button onClick={handleOpenSecond} className={cls['complex__btn']}>Блок 2</button>
                <button onClick={handleOpenThird} className={cls['complex__btn']}>Блок 3</button>
                {/* {
                    btns.map((btn, i) => (
                        <button onClick={handleOpenModal} className={cls['complex__btn']}>{btn.block}</button>
                    ))
                } */}
                {/* <Modal /> */}
                <PlanModal close={handleClose} blockFirst={blockFirst} blockSecond={blockSecond} blockThird={blockThird} />
            </div>
        </div>
    )
}

export default Complex