import React from 'react'
import Navbar from '../../components/Navbar'

import cls from './SalePage.module.scss'
import sale from '../../assets/images/saleImg.png'
import Contact from '../../components/Contact'


const SalePage = () => {

    return (
        <div className={cls['sale']}>
            <Navbar />
            <div className={cls['sale__info']}>

                <div className={cls['sale__wrapper']}>
                    <img src={sale} alt="" />
                    <div className={cls['sale__content']}>
                        <h2>Грандиозная новогодняя Акция от ЖК«IPAK YO’LI»!🎄</h2>
                        <p>Приобретайте квартиру своей мечты в самом сердце горда прямо сейчас со скидкой до 15%! Делайте правильный выбор своего будущего и начните новый 2023 год с новой недвижимостью вместе с нами!🎅🏻 </p>
                    </div>

                </div>
            </div>
            <Contact />
        </div>
    )
}

export default SalePage